.portalEditScreen{
    height: 100%;
    overflow-y: overlay;
}

.portalEditContent{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 50px;
    
}

.portalEditBlockTop{
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.portalEditSec1Content{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    gap: 50px;
}

.portalEditRow{
    display: flex;
    gap: 50px;
}

.portalEditColumn{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
}

.portalEditInput{
    width: 100%;
}

.portalEditCountry{
    width: 100%;
}

.portalEditSubtitles{
    margin-bottom: 10px;
}

.portalEditPassRow{
    display: flex;
    gap: 20px;
}