
.headerBkgImg{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}




.mapsInput{
    width: 500px;
    height: 45px;
    background-color: #f1f1f1;
    border-color: transparent;
    border-radius: 5px;
    padding: 10px;
    padding-left: 15px;
    font-size: 18px;
  }

  .formInputs{

        
        margin-top: 50px;
        display: flex;
        flex: 100%;
        flex-direction: column;
        /* align-items: center; */
        

  }

  .formInput{
      background-color: #f1f1f1;
      color: black;
      border-color: transparent;
      border-radius: 0px;
      width: 500px;
      height: 45px;
      padding: 5px;
      padding-left: 15px;
      font-size: 18px;
  }

  
.formInput::placeholder{
    color: #888888;
}

.errorInput{
    border-style: solid;
    border-width: 1px;
    border-color: red;

}



  .formTitleText{

    color: black;
    font-size: 17px;
    text-align: start;
    width: 100%;
  }


  .formRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 50px;
      margin-top: 25px;
  }

  
  .formRowSearch{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 1050px;
    margin-top: 25px;
}


  
  .formColumn{
    display: flex;
    flex-direction: column;
}


.formSubmitDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 50px;
}

.formSubmitBtn{
    height: 52px;
    width: 151px;
    margin-top: 25px;
    font-size: 18px;
    background-color: #7F79FC;
    border-radius: 5px;
    color: white;
    /* font-size: 18px; */
    border-color: transparent;
    transition: 0.3s;
}

.formSubmitBtn.disabled{
    background-color: #888;
}

.formError{
    color: red;
    font-size: 12px;
    min-height: 20px;
    margin-top: 10px;
}

.alignLeft{
    text-align: left;
}

.formSection{
    color: black;
    font-size: 27px;
    margin-top: 50px;

    margin-bottom: 5px;
}
.formSmall{
    font-size: 12px;
    color: #777;
}

.mainFormDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}



.formHeader{
    height: 249px;
    width: 100%;
    color: white;
    /* background-color: #7F79FC; */

}

.logoDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 24px;
    left: 24px;
    cursor: pointer;
}

.logoImg{
    height: 160px;
    cursor: pointer;
}

.headerText{
    width: 100%;
    height: 249px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    /* background-color: #7F79FC; */
    position: absolute;
}


.popUpDiv{
    /* position: absolute; */
    background-color: #1C1C1C77;
    height: max-content
}

.submittedPopUp{
    background-color: white;
    height: 570px;
    width: 673px;
    border-radius: 20px;

}

.mainFormDiv, .popUpDiv{
    grid-column: 1;
    grid-row: 1;
  }
  
  .outerDuve{
    /* background-color: aqua; */
    overflow: hidden;
    min-height: 100%;
    display: grid;
}

.celebrationImg{
    height: 271px;
    margin-left: 55px;
    
}

.titleLeft{
    text-align: left;
}

@media (max-width:1100px) {
    
    
    .formRow{flex-direction: column; gap: 25px;}
    .formRowSearch{flex-direction: column; }
    .titleLeft{text-align: center;}
    .formSubmitDiv{align-items: center;}
    .logoDiv{align-items: center;}
    .headerText{top: 150px;}
    .formHeader{height: 400px;}


    .formInput{width: 90%;}
    .formInputs{width: 90%;}
    .formColumn{width: 100%;}
    .formRowSearch{width: 90%;}
    .mapsInput{width: 95%;}
}

@media (max-width:600px) {
    .headerText{top: 200px;}
    .formHeader{height: 500px;}
}



.headerBF{
    height: 100px;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-y: none;
}

.logoIV{
    height: 80px;
    width: 300px;
    object-position: -40px;
    object-fit: cover;
    position: relative;
}