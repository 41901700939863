@import './color.css';


.header{
    min-height: 72px;
    background-color: var(--primaryPurple);
    /* padding-top: 19px;
    padding-bottom: 19px; */
}

.headerTest{
    position: fixed;
    top: 0;
    right: 0;
    background-color: #5B54B0;
    color: white;
    padding: 10px;
    z-index: 1000;
}

.headerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /* background-color: #01A4BD; */
}

.routesRow{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}

.logoHeader{
    margin-right: 8px;
    height: 32px;
    width: auto;
    cursor: pointer;
}


.buttonsRowHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    color: white;
}

/* 
.headerSignInBtn{
    border-color: white;
    background-color: transparent;
    color: white;
} */


.headerProfile{
    display: flex;
    flex-direction: column;
}

.headerProfileTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    font-weight: 400;
    font-size: 14px;

    cursor: pointer;
}

.headerProfileCircle{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #5B54B0;

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;
}

.headerProfileDown{
    height: 16px;
    width: 16px;
}


.headerProfileOptions{
    position: absolute;
    top: 80px;
    width: 224px;

    background-color: white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: column;
    
    gap: 10px;
    padding-top: 8px;

    transition: all 0.3s;
    z-index: 20;
}

.headerProfileOption{

    height: 48px;
    display: flex;
    align-items: center;
    gap: 22px;

    cursor: pointer;
}

.headerProfileOption.signOut{
    height: 64px;
    border-top: #72717C;
    border-style: solid;
    border-width: 1px;
}

.hpoImg{
    height: 24px;
    width: 24px;
    color: #72717C;
    margin-left: 16px;
}

.headerProfileOption:hover{
    background-color: #F2F3FD;
}


@media (max-width:900px) {
    .headerRow{
        padding-left: 20px;
        padding-right: 20px;
    }

    .logoHeader{
        height: 32px;
        width: auto;
    }
}