:root {
    --primaryPurple:#766CE3;
    --fontPrimary: #151329;
    --fontSecondary: #666666;

    --backgroundPurple: #F2F3FD;
    --backgroundWhite: #FFFFFF;

    --purple70: #908BDE;
    --purple2: #a39fdb;
  }