.bookingScreen{
    flex: auto;
    padding-bottom: 50px;
    
}


.bookingProgressBar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 76px;
}

.bookingProgressBarComp{
    width: 800px;
}

.bookingPassOverview{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    /* background-color: violet; */
    margin-top: 49px;
}
.passOverviewHeaders{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.poSelected{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    

    font-size: 20px;
    font-weight: 700;
    color: '#1511329';
}

.poSelectedPassRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.poSubCardRow{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.poSelectedGym{
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 100%;
}

.poGymImg{
    border-radius: 4px;
    width: 250px;
    height: 171px;
    margin-top: 4px;
    margin-left: 4px;
}

.poGymColumn{
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
}
.poGymSubColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.poSelectedPass{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
}
.poPassSubColumn{
    width: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.poInputsSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 64px;
}


.poDateSection{
    width: 838px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.poDateTitles{
    display: flex;
    flex-direction: column;
    gap: 4px;
}


.poDetailsSection{
    
    /* width: 538px; */
    display: flex;
    flex-direction: column;
    /* gap: 200px; */
    /* background-color: aqua; */
}

.poDetailsInputs{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
}

.PrivateDatePickerToolbar-penIcon {
    visibility: hidden;
  }

  .poButtons{
      display: flex;
      gap: 16px;
  }


  .bookingPayment{
      width: 100%;
      align-items: flex-start;
      /* background-color: red; */
  }

  .paymentForm{
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 50%;

  }



  
.pcSelectedPass{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 16px;
    margin-right: 16px;
    align-items: flex-start;
    gap: 20px;   
}


.pcPassSubColumn{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.pcCalendarImg{
    color: #88888F;
}
.pcCalendarImg.activatedCalendar{
    color: black;
}


.activatedBubble{
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-weight: 700;
    font-size: 13px;
    color:#FA948C;

    border-radius: 8px;
    border-style: solid;
    border-color: #FA948C;
    border-width: 2px;

}

.activatedBubble.green{
    color:#83C17B    ;
    border-color: #83C17B;
}

.activatedCircle{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #FA948C;
}

.activatedCircle.green{
    background-color: #83C17B;
}

.activatedNoBorder{
    border-style: none;
    min-width: 164px;
}


.statusExplanation{
    max-width: 604px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.explanationSubColumn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.formsScreen{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}


.formStatusBar{
    display: flex;
    align-items: center;
    gap: 8px;
}

.formScrollable{
    width: 99%;
    max-height: 574px;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;

}

.formContent{
    
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.formScrollable.terms{
    max-height: 460px;
}


.formScrollable::-webkit-scrollbar {
    width: 11px;
    margin-right: 20px;
  }
  
  /* Track */
  .formScrollable::-webkit-scrollbar-track {
    background: #F9F9F9;
    border-radius: 10px;
  }
   
  /* Handle */
  .formScrollable::-webkit-scrollbar-thumb {
    background: #72717C; 
    border-radius: 35px;
    /* border-right: 4px white solid; */

  }


  .termsSection{
      display: flex;
      flex-direction: column;
      gap: 20px;
  }

  .signingArea{
      display: flex;
      flex-direction: column;
  }

  .signingAreaBase{
      /* width: 100%; */
      height: 62px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
      background-color: #F9F9F9;

      font-size: 14px;
      font-weight: 400;
  }


  .signatureClearBtn{
      color: #72717C;
      cursor: pointer;
  }
  .signatureClearBtn:hover{
    color: black
    
    }


.questionDivider
{
    width: 100%;
    height: 1px;
    background-color: #72717C;
}
  


.questionComponent{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.questionTitles{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.questionAnswers{
    margin-left: 10px;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    column-gap: 0px;
    row-gap: 6px;
}


.multipleChoiceAnswer{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    font-weight: 500;
    font-size: 14px;

}




.bookingLoadingScreen{
    position: fixed;
    background-color: #15132980;
    width: 100%;
    /* bottom: 48px;
    top: 142px; */
    top: 0px;
    bottom: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    z-index: 100;
}


.paymentLoadingPopUp{
    height: 131px;
    width: 476px;
    background-color: white;
    border-radius: 8px;

    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 68px;
}

.paymentLoadingTitle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}



.cancelBookingPopUp{
    height: 131px;
    width: 476px;
    background-color: white;
    border-radius: 8px;

    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 40px;
}



/* ------------------------------------------------------------------------- */


.myPassesScreen{
    width: 100%;
}

.myPassesTitles{
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.inactivePasses{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 64px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}



.activePasses{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 100px;
}

.activePassesTitles{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.myPassesColumn{
    width: 100;
    display: flex;
    flex-direction: column;
    gap: 40px;
}


.completedBooking{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.passDownloadButtons{
    display: flex;
    gap: 16px;
    align-items: center;
}

.activateBookingScreen{
    width: 100%;
    /* height: 100%; */
    padding-bottom: 100px;
}

.walletPassBtn{
    height: 36px;
    cursor: pointer;
}

.viewAllPasses{
    cursor: pointer;
}

.viewAllPasses:hover{
    text-decoration: underline;
}

.poCard{width: 836px;}
.poSubCard{width: 200px;}

.poCompleted{width: 624px;}
.signingCard{width: 600px;}

.signCanvas{
    width: 100%;
    height: 150px;
}

.viewAllPasses.mobile{display: none;}

@media (max-width:900px) {
    .bookingProgressBarComp{
        width: 400px;
    }

    .bookingPassOverview{
        padding: 20px;
        box-sizing: border-box;
        
    }

    .poSelectedPassRow{
        flex-direction: column;
    }
    


    .poCard{
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .poCompleted{
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .poSubCard{
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        justify-content: center;
        
    }

    .poSelected{
        width: 100%;
    }

    .poSelectedGym{
        flex-direction: column;
    }

    .poGymImg{width: 100%; margin: 0px;}
    .poSelectedPass{
        align-items: flex-start;
        justify-content: center;
    }

    .poInputsSection{
        flex-direction: column;
        width: 100%;
    }

    .poDateSection{width: 100%;}

    .paymentForm{width: 100%;}

    .signingCard{width: 100%;}

    .questionAnswers{grid-template-rows: auto auto auto auto;}
    .completedBooking{width: 100%;}

    .passDownloadButtons{
        width: 100%;
        flex-flow: wrap;
        flex-direction: row;
    }

    .viewAllPasses{display: none;}
    .viewAllPasses.mobile{display: inline;}


    .myPassesTitles{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .inactivePasses{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .activePasses{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }


    /* .pdfBtn{width: 10px;} */
}