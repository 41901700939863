.profileScreen{

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
}


.profileTopSection{
    width: 100%;
    height: 91px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}

.profileUserTitles{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
    
    justify-content: center;
    align-items: flex-start;
}


.profileMainSection{
    width: 100%;
    height: 100%;
    background-color: #F2F3FD;
}


.profileContentRow{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 48px;
    gap: 40px;
}


.profileContentColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    
}

.profileContentTitles{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.profileTilesColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}


.profileTile{
    width: 495px;
    height: 127px;

    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.profileTile.underConstruction{
    background-color: #ECECF2;
    cursor: auto;
}

.profileTile:hover{
    box-shadow: 0px 1px 3px 1px rgba(118, 108, 227, 0.7);
}
.profileTile.underConstruction:hover{
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.profileTileImg{
    width: 35px;
    height: 35px;
    margin-left: 20px;
}

.profileTileColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}



.updateProfileSubmitSection{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
    margin-top: -42px;
}


.updateLoginSubmitSection{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}



.updateLoginInputsSection{
    display: flex;
    flex-direction: row;
    gap: 124px;
}

.updateLoginLine{
    height: 100%;
    margin-bottom: 22px;
    width: 1px;
    background-color: #AEACFF;
}


.updateLoginTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

}



.deleteAccountLeftSide{
    width: 496px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    padding-top: 20px;
}



@media (max-width:900px) {

    .profileScreen{height: auto;}
    .profileMainSection{height: auto; padding-top: 50px; padding-bottom: 50px;}

    .profileContentColumn{width: 100%;}
    .profileTilesColumn{width: 100%;}
    .profileContentRow{
        flex-direction: column-reverse;
        padding: 20px;
        height: 100%;
        gap: 70px;
        width: 100%;
        box-sizing: border-box;
    }

    .profileTile{width: 100%;}

}