
@import './color.css';

.footer{
    width: 100%;
    min-height: 78px;
    background-color: var(--backgroundPurple);
}

.footerContent{
    width: 100%;
    min-height: 78px;
    background-color: var(--backgroundPurple);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--fontPrimary);
}


.footerBtns{
    display: flex;
    flex-direction: row;
    gap: 32px;
}



.footerBtn{
    cursor: pointer;
}


@media (max-width:900px) {

    .footer{
        padding: 30px;
        min-height: auto;
        box-sizing: border-box;
    }

    .footerContent{
        flex-direction: column-reverse;
        gap: 50px;
        min-height: auto;
        justify-content: center;
        align-items: center;
    }

    
}