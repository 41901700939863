.discoverScreen{
    flex: auto;
    position: relative;
    background-color: white;
    padding-bottom: 50px;
}


.citySection{
    position: relative;
    height: 261px;
}

.cityImg{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cityTitles{
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    color: #7666E3;

    background-color: #000000B2;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cityName{
    font-size: 48px;
    font-weight: 700;
    color: white;
}


.discoverList{
    
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 32px;
    row-gap: 32px;
    justify-content: flex-start;
    
    
    margin-bottom: 50px;
}


.gymTile{
    
    position: relative;

    width: 328px;
    min-width: 274px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: -10px;

    cursor: pointer;
}



.gymTileImg{
    height: 200px;
    min-height: 156px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.gymTileImg.mapTile{
    height: 155px;
}

.gymTileContent{
    height: 206px;
    /* width: 100%; */
    border-radius: 8px;

    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 26px;
    padding-right: 26px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: -10px;
    background-color: white;

    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.gymTileContent.hover{
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
}

.gymTileContent.clicked{
    background-color: #F2F3FD;
}

.gymTileContent.mapTile{
    height: 154px;
    gap: 8px;
}

.gymTileTitle{
    
    width: 100%;
    display: flex;
    flex-direction: column;
}

.gymTileMapRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px
}

.gymTileShowOnMap{
    color: #72717C;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.gymTileShowOnMap:hover{
    color: #83C17B;
}

.gymTileDistanceRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px
}


.gymTileOptionRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gymTileOptionsText{

    display: flex;
    flex-direction: row;
    color: #151329;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    min-width: 106px
}

.gymTilePriceRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    color: #766CE3;
}

.gymTilePrice{
    font-weight: 700;
    font-size: 28px;
}

.gymTilePerDay{
    font-weight: 400;
    font-size: 14px;
}




.gymTileRatingSection{
    position: absolute;
    top: 20px;
    right: 20px;

    /* height: 42px;
    width: 71px; */

    border-radius: 4px 4px 4px 0px;
    background-color: white;
    padding: 2px 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.gymTileRating{
    color: #7666E3;
    font-weight: 400;
    font-size: 18px;
}

.gymTileAmountReviews{
    color: #72717C;
    font-weight: 400;
    font-size: 9px;
}




.gymTileOptionsPopUp{

    position: absolute;
    top: 328px;
    left: 144px;
    z-index: 100;

    /* height: 160px; */
    width: 220px;
    border-radius: 8px;
    background-color: white;
    
    padding: 20px;

    display: flex;
    flex-direction: column;
}


.gymTileOptionsTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.gymTileOptionsLine{
    width: 100%;
    height: 1px;
    background-color: #83C17B;
}

.gymTileOptionsContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.gymTileOptionsRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.gymTileOptionPrice{
    color: #151329;
    font-weight: 700;
    font-size: 14px;
}








/* ------------------------------------------------------------------------ */


.gymFilter{
    min-width: 210px;
    height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    /* gap: 12px; */
    padding: 0px 16px;
    background-color: #F2F3FD;
}
/* 
.gymFilter.open{
    justify-content: flex-start;
} */

.gymFilter.hoverState{
    background-color: #DFDDFE;
}

.gymFilter.pressed{
    background-color: #CECBFD;
}


.gymFilterTitle{
    margin-top: 12px;
    margin-bottom: 12px;
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: yellowgreen; */

    color: #151329;
    font-weight: 700;
    font-size: 14px;

    cursor: pointer;
}



.gymFilterCircle{
    color: #151329;
    font-size: 12px;
    font-weight: 500;

    background-color: #AEACFF;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}


.gymFilterContents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    margin-top: 8px;
    margin-bottom: 12px;
    height: 139px;
    /* background-color: #72717C; */
    margin-right: -8px;
    overflow-y: auto;
}

.gymFilterContents.overflowState{
    margin-right: -12px;
}


.gymFilterContents::-webkit-scrollbar {
    width: 6px;
    
  }
  
  /* Track */
  .gymFilterContents::-webkit-scrollbar-track {
    background: #DFDDFE;
    border-radius: 10px;
  }
   
  /* Handle */
  .gymFilterContents::-webkit-scrollbar-thumb {
    background: #72717C; 
    border-radius: 35px;
    /* border-right: 4px white solid; */

  }




.gymFilterOption{
    width: 94%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


/* --------------------------------------------------------------------- */



.discoverSearchRow{
    width: 100%;
    height: 76px;
    margin-top: -35px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    
}


.discoverInput{
    width: 60%;
    justify-content: center;
    
}



.discoverFilterSection
{
    width: 100%;
    display: flex;
    flex-direction: column;
    
}

.discoverFilterTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 20px 0px;
}

.discoverFilterTitles{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: baseline;
}

.clearFiltersBtn{
    padding-left: 10px;
    cursor: pointer;
}

.clearFiltersBtn:hover{
    text-decoration: underline;
}

.discoverFilterArrows{
    display: flex;
    flex-direction: row;
    width: 68px;
    gap: 20px;
}

.discoverFilterArrowsMap{
    gap: 10px;
}
.discoverFilterArrow{
    color:#151329;
    cursor: pointer;
}


.discoverFilterArrow.disabled{
    color: #DDDDE3;
    cursor: auto;
}





.discoverFilterRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* min-height: 44px; */
    overflow-x: hidden;
    padding-right: 20px;
}

.discoverFilterRow.map{
    height: auto;
    position: absolute;
    width: auto;
    z-index: 1000;
    gap: 10px;
    top: 25px;
    left: 36%;
    right: 47px;
    margin-right: 10px;
    /* background-color: #7666E3; */

}


.discoverAccommodationAutoCompleteDiv{
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
}

.discoverAccommodationAutoComplete{
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
}



.discoverSortSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 32px;
}


.discoverShowMap{
    height: 64px;
    width: 181px;

    background-image: url(../../public/assets/mapBtnBkg.png);

    display: flex;
    justify-content: center;
    align-items: center;
}

.discoverSortRow{
    display: flex;
    flex-direction: row;
    gap:8px;
    
}


.noGymsDiscover{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 70px;
}

.noGymsDiscoverText{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noGymsIcon{
    width: 300px;
}


/* --------------------------------------------------------------------- */

.mapPopUpScreen
{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #15132980;

    width: 100%;
    height: 100vh;
    
    /* padding: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

}

.mapPopUp{
    
    height: 90%;
    width: 95%;   
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;

    /* margin-bottom: 100px; */
    position: relative;
}

.mapPopUpClose{
    position: absolute;
    top: 20px;
    right:20px;
    cursor: pointer;
    z-index: 400;
    /* background-color: #7666E3; */
}

.mapPopUpTitles{
    height: 100px;
    width: 78%;

    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
    z-index: 200;
}



.accommodationInputImg{
    height: 20px;
}

.popUpAccInput{
    width: 35%;
    /* width: 570px; */
    
}

.accommodationAutoComplete{
    height: 50px;
    border: transparent;
    padding-left: 20px;
    width: 510px;
}

.accommodationAutoComplete:focus{
    /* background-color: #7666E3; */
    /* -webkit-backface-visibility: hidden; */
    
}



.mapDiv{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #7666E3;
}

.map{
    height: 100%;
    width: 100%;

    border-radius: 0px 0px 8px 8px;

    z-index: 50;
}


.searchThisAreaContainer{
    position: absolute;
    left: 0;
    right: 0;
    top: 27px;

    z-index: 100;
    display: flex;
    justify-content: center;

    
}

.searchThisArea{
    height: 34px;
    width: 153px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 64px;
    background-color: #F5F5F5;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
}


.mapLegend{
    position: absolute;
    right: 96px;
    bottom: 42px;
    z-index: 100;

    

    border-radius: 64px;
    background-color: #F5F5F5;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
    transition: all 0.7s;
}

.mapLegendClosed{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 8px;

    color: #000000DE;
    font-weight: 500;
    font-size: 14px;

}

.mapLegendOpen{
    /* background-color: red; */
    height: 40px;
    display: flex;
    align-items:flex-end;
    gap: 24px;
    padding: 8px 24px 8px 8px;
}

.mapLegendArrowDiv{
    height: 100%;
    display: flex;
    align-items: center;

}


.mapLegendColumn{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mapLegendImg{
    height: 18px;
}



.mapZoomBtns{
    position: absolute;
    right: 32px;
    bottom: 33px;
    z-index: 100;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mapZoomBtn{
    height: 56px;
    width: 56px;
    border-radius: 50%;

    background-color: #151329;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mapZoomIcon{
    /* background-color: white; */
    color: white;
}


.mapInfoWindow{
    background-color: #7666E3;
}


.gm-style-iw + div {display: none;}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style .gm-style-iw-c {
    padding: 0;
    
  }

.gm-style-iw .gm-style-iw-d {
overflow: auto !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after { 
  background: transparent;
}


.mobileDiscoverRow{
    display: none;
    max-height: 10px;
    /* background-color: red; */

}
.closeFilters{
    display: none;
}

.mobileSort{
    /* background-color: red; */
}

.mobileOptionsSelect{
    /* display: none; */
    width: 0px;
    /* background-color: #766CE3; */
    z-index: -20;
}

.mobileGPTitle{display: none;}


@media (max-width:900px) {
    .discoverSearchRow{
        display: flex; 
        flex-direction: column;
        margin-top: -15px;
    }
    .closeFilters{
        display: block;
    }

    .discoverInput{
        width: 90%;
    }

    .findGymsBtn{width: 90%;}
    
    .mobileDiscoverRow{
        display: block;
        padding: 30px;
        display: flex;
        justify-content: center;
        color: #7666E3;
        gap: 30px;
    }

    .mobileSortBox{
        width: 200px;
    }

    .mobileSort{
        width: 300px;
        z-index: 100;
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .discoverAccommodationAutoCompleteDiv{
        justify-content: center;
        display: flex;
    }
    .discoverAccommodationAutoComplete{width: 90%;}
    .discoverSortSection{display: none;}

    .discoverList{
        display: flex;
        flex-direction: column;
        align-items: center;
     }

     .discoverList.showFilters{display: none;}

     .discoverFilterSection{display: none;}

     .discoverFilterSection.showFilters{
        display: block;
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
        /* bottom: 0; */
        /* height: auto; */
        height: 100%;
        width: 100%;
        z-index: 30;

        padding: 20px;
        padding-bottom: 50px;

        box-sizing: border-box;
     }

     .discoverFilterArrows{display: none;}

     .discoverFilterRow{
        width: 100%;
        padding: 10px;
        flex-direction: column;
        box-sizing: border-box;
     }

     /* .discoverFilterSection{height: auto;} */

     .gymFilterContents{
        height: auto;
        overflow-y: hidden;
        margin-top: 18px;
        margin-bottom: 23px;
     }

     .mapPopUpTitles{width: 90%;}
     .popUpAccInput{width: 85%;}
     .discoverFilterRow.map{display: none;}
     .discoverFilterArrowsMap{display: none;}

     .mapLegendOpen{
        flex-direction: column; 
        align-items: center;
        height: auto;
        padding: 8px;
    }

    .gymTileOptionsPopUp{
        left: 100px;
        top: 390px;
    }

    .desktopGPTitle{display: none;}
    .mobileGPTitle{
        display: inline;
        font-weight: 600;
        font-size: 28px;
    }

}