

.gymProfileImageSection{
    width: 100%;
    height: 320px;
    
    display: flex;
    flex-direction: row;
    gap:16px;
}

.gymProfileImage{
    object-fit: cover;
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    min-height: 0;

    border-radius: 8px;
}

.gymProfileImageRow{
   
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    min-height: 0;


    
    display: flex;
    flex-direction: row;
    gap: 16px;
}


.gymProfileImageColumn{
    
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    min-height: 0;


    display: flex;
    flex-direction: column;
    gap: 16px;
}


.gymProfileMapBtn{
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    min-height: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../public/assets/mapBtnProfile.png);
    border-radius: 8px;

}



.gymProfileSocialMediaRow{
    display: flex;
    gap: 20px;
    margin-top: 16px;
}

.gymProfileSocialIcon{
    height: 16px;
    width: 16px;
    cursor: pointer;
}


.gymProfileMainSection{
    width: 100%;
    display: flex;
    gap: 32px;
}

.gymProfileInfoSection{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.gymProfileTitleColumn
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 20px;
}

.gymProfileTitleRow{
    display: flex;
    align-items: flex-start;
    gap: 18px;
    
}

.gymProfileTitleRating{
    height: 27px;
    width: fit-content;
    padding: 10px;
    background-color: #83C17B;
    border-radius: 4px 4px 4px 0px;

    color: white;
    font-weight: 400;
    font-size: 18px;
}


.gymProfileBio{
    margin-top: 20px;
    text-align: left;
}


.gymProfileRequirementsSection{
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gymProfileRequirementsColumn{
    display: flex;
    flex-direction: column;
}

.gymProfileFacilitySection{
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-top: 40px;
}

.gymProfileAmenitiesSection{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 16px;
}




.gymProfilePassesSection{

    /* width: 518px; */
    max-width: 475px;
    min-width: 264px;
    height: fit-content;
    min-height: 188px;
    margin-top: 164px;

    background: #FFFFFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.gymProfilePassesTitles{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: flex-start;
}

.gymProfilePassesLine{
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 8px;
}

.gymProfilePassesGrid{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: flex-end;
    
    column-gap: 40px;
    row-gap: 30px;
}




.gymProfilePassOption{
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 113px;
    
}

.gymProfilePassOptionTitles{
    min-height: 44px;
    /* height: fit-content; */
    width: 113px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.gymProfileOpeningHoursSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 40px;
}

.gymProfileOpeningHoursGrid{
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto auto auto;
    column-gap: 72px;
    row-gap: 10px;
}

.gymProfileDayHours{

    width: 202px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gymProfileDayTimes{
    max-width: 130px;
}




@media (max-width:900px) {

    .gymProfileImageSection{
        width: 100%; 
        height: auto; 
        padding: 20px;
        box-sizing: border-box;
    }
    .gymProfileImageRow{display: none;}

    .gymProfileSocialMediaRow{
        padding-left: 20px;
    }

    .gymProfileMainSection{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        flex-direction: column;
    }
    .gymProfileInfoSection{
        width: 100%;
    }

    .gymProfilePassesSection{
        margin-top: 0px;
    }

    .gymProfilePassesGrid{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .gymProfilePassOption{
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        
    }

    .gymPassBtn{width: 40%;}

    .gymProfileRequirementsSection{padding-left: 20px; padding-right: 20px;}
    .gymProfileFacilitySection{
        flex-direction: column;
        padding-left: 20px; 
        padding-right: 20px;
    }

    .gymProfileAmenitiesSection{width: 100%;}
    .gymProfileOpeningHoursSection{
        padding-left: 20px; 
        padding-right: 20px;
    }

    .gymProfileOpeningHoursGrid{
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }
}