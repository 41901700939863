@import './color.css';


.bkgImg{
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center -718px;
    position: absolute;
    top: 0;
    left: 0;
}



@media (max-width:2000px) {
    .bkgImg{
        object-position: center -600px;
    }
}


@media (max-width:1700px) {
    .bkgImg{
        object-position: center -500px;
    }
}


@media (max-width:1500px) {
    .bkgImg{
        object-position: center -300px;
    }
}



@media (max-width:1300px) {
    .bkgImg{
        object-position: center -100px;
    }
}



.titleText{
    font-size: 4.1vw;
}

.mediumText{
    font-size: 1.435vw;
}

.smallText{
    font-size: 0.76vw;
}


.subText{
    font-size: 1vw;
    font-weight: 400;
    text-align: center;
}


.comingSoonCentre{
    height: 61%;
    width: 45%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* background-color: aquamarine; */
}




.progressBar {
    width: 729.5px;
    height: 22px;
    background: #373737;
    border-radius: 20px;
  }
  .progressBar::-moz-progress-bar
  {
    background: #7F79FC;
      border-radius: 20px
  }
  .progressBar::-webkit-progress-bar
  {
      background: transparent;
  }
  .progressBar::-webkit-progress-value
  {
    background: #7F79FC;
      border-radius: 20px
  }

  


.relative{
    position: relative;
}

.absolute{
    position: absolute;
    top: 0;
    left: 0;
}

.tint{
    background-color: #363636E5;
}

.margin-60-120{
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 6.25%;
    right: 6.25%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    /* background-color: aquamarine; */
}

.centreChild{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}


.baseText{
    color: white;
    

    /* position: absolute;
    bottom: 0px;
    width: 100%; */
}

.font28{
    font-size: 28px;
}

.fontPurple{
    color: #7F79FC;
    font-family: "BeVietnamProBold";
}



.gap48{
    gap: 48px;
}

.gap12{
    gap: 12px;
}




.inputRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}




.emailInput
{
    height: 100%;
    width: 580px;
    background-color: #6C6C6C;
    border-radius: 5px;
    transition: 0.1s;
}

.getUpdatesBtn{
    height: 52px;
    width: 151px;
    background-color: white;
    border-radius: 5px;
    color: #6C6C6C;
    /* font-size: 18px; */
    border-color: transparent;
    transition: 0.3s;
    cursor: pointer;
}

.updatesBtnSubmitted{
    background-color: green;
    color: white;
}

.emailInput:hover
{
    border-radius: 5px;
    border-color: #7F79FC;
    border-style: solid;
}

.getUpdatesBtn:hover{
    background-color: var(--primaryPurple);
    color: white;
}


.updatesBtnSubmitted:hover{
    background-color: green;
    color: white;
}

.gotToFormBtn{
    height: 52px;
    width: 277px;
    background-color: var(--primaryPurple);
    border-radius: 5px;
    color: white;
    /* font-size: 18px; */
    border-color: transparent;
    transition: 0.3s;
    cursor: pointer;
}

.gotToFormBtn:hover{
    border-color: white;
}




.emailIV{
    height: 24px;
    width: 24px;
    margin-left: 20px;
    margin-right: 10px;
}

.emailTextEdit{
    all: unset;
    color: white;
    justify-content: start;
    text-align: start;
    
}

.emailTextEdit::placeholder{
    color: white;
}


/* 
input[type=text]{
    all: unset;
    text-align: start;
} */


.captcha
{
    min-height: 1000px;
    /* / */
    /* background-color: aqua; */
}

.demoBtn{
    width: fit-content;
}



@media (max-width:1300px) {
    .bkgImg{
        object-position: center 0px;
    }
    .comingSoonCentre{width: 100%; height: 50%;}
    .titleText{font-size: 6vw;}
    .subText{font-size: 2.3vw;}
    .smallText{font-size: 1.7vw;}
    .mediumText{font-size: 3vw;}
    .emailInput{width: 400px;}
    .gotToFormBtn{width: 300px;}
}

@media (max-width:900px) {
    .inputRow{flex-direction: column;}
    .emailInput{width: 100%; height: 70px;}
    .getUpdatesBtn{width: 100%; height: 50px;}
    .gotToFormBtn{width: 100%; margin-top: 20px; height: 50px;}
}


@media (max-width:750px) {
    
    /* .comingSoonCentre{width: 500px; height: 500px;} */
    .progressBar{width: 470px; height: 15px;}
    .bkgImg{object-position: center 0px; }
    
    
}


@media (max-width:500px) {
    
    /* .comingSoonCentre{width: 500px; height: 500px;} */
    .progressBar{width: 300px; height: 12px;}
    .bkgImg{object-position: center 0px;}
    .smallText{font-size: 2.3vw;}
    .subText{font-size: 2.7vw;}
    

}
