
.test1{
    font-size: 40px;
    color: red;
}

.outerdiv .test1{
    
    color: #000;
} 
.outerdiv .test2{
    color: aqua;
}



.signingTest{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}



.terms{
    width: 1300px;
}

.signingSection{
    margin-top: 30px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}


.signatureClear{
    width:500px;
    height: 50px;
    cursor: pointer;
}

.signatureSave{

    cursor: pointer;
    width: 300px;
    height: 40px;

    border-radius: 5px;
    border-color: transparent;
    background-color: #7F79FC;
    color: white;
    margin-top: 50px;
}