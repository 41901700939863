 @import './color.css';

 .loginScreen{
     flex: auto;
 }


 .backBar{
    background-color: var(--backgroundPurple);
    height: 70px;
 }

 .backBarContent{
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     height: 100%;
     width: fit-content;
     gap: 8px;
     cursor: pointer;
     
     /* background-color: red; */
 }


 .loginMainSection{
     
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-top: 49px;
 }

 .loginLeftSide{
     width: 496px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
 }

 .loginLeftContainer{
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: flex-start;
 }

 .loginHeaders{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
 }

 .loginInputs{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
 }

 .loginEmailInput{
     height: 56px;
     width: 100%
 }
 
 .loginPasswordInput{
    height: 56px;
    width: 100%
}


.loginSubmitSection{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;

    
}

.loginForgotPassword{
    color: var(--purple70);
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
}

.loginRegisterSection{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.loginRegisterButton{
    color: var(--primaryPurple);
    text-decoration: underline;
    cursor: pointer;
}


 .loginRightSide{
     display: flex;
     justify-content: center;
     margin-top: 19px;
     
 }

 .loginScreenIcon{
     width: 334px;
     height: 388px;
 }



 .registerScreen{
    
    padding-bottom: 50px;
     flex: auto;
 }

 .registerMainSection{
    width: 100%;
    margin-top: 49px;
    /* min-height: 500px; */
 }

 .registerInputView{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
 }


 .registerInputsSection{
     display: flex;
     flex-direction: row;
     gap: 80px;
 }

 .registerInputColumn{
     width: 400px;
     display: flex;
     flex-direction: column;
     gap: 20px;
 }


input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}

.phoneInput{
    border-color: red;
}


.phoneVerifyScreen{
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000a;

    display: flex;
    align-items: center;
    justify-content: center;
}

.phoneVerifyPopUp{
    height: 337px;
    width: 476px;
    background-color: white;
    border-radius: 8px;

    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 40px;
}

.verifyPopUpContent{
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

.verifySuccessContent{
    width: 100%;
    display: flex;
    justify-content: center;
}


.verifyCodeInputRow{
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
}

.verifyCodeInput{
    height: 56px;
    width: 56px;
    text-align: center;
}

.verifySuccessImg{
    height: 120px;
    margin-top: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.twoFVOne{display: flex; justify-content: flex-start;}
.twoFVTwo{display: none;}

.inputErrorIcon{color: #FA948C;}


.registerTermsLink{ text-decoration: underline;}
.registerTermsLink:hover{color: var(--primaryPurple);;}

@media (max-width:900px) {

    .backBar{height: 90px; padding: 10px; box-sizing: border-box;}

    .loginRightSide{display: none;}

    .loginMainSection{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .registerMainSection{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        
    }

    /* .updateLoginLine{display: none;} */

    .registerInputsSection{
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .registerInputColumn{width: 100%;}

    .twoFVOne{display: none;}
    .twoFVTwo{display: flex; justify-content: flex-start;}
    
    .phoneVerifyPopUp{
        width: 90%;
        box-sizing: border-box;
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .verifyCodeInputRow{
        width: 100%;
        gap: 5px;
    }
}