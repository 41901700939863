
.centreJC{
    justify-content: center;
    gap: 20px;
}


.centreAI{
    align-items: center;
    gap: 20px;
}

.fontWhite{
    color: white;
}

.fontBlack{
    color: black;
}
.fontGrey{
    color: #888888;
}
.fontAccent{
    color: #7F79FC;
}

.fontRegular{
    font-family: "BeVietnamProRegular";
}

.fontBold{
    font-family: "BeVietnamProBold";
}

.font15{
    font-size: 15px;
}
.font18{
    font-size: 18px;
}

.font26{
    font-size: 26px;
}

.font40{
    font-size: 40px;
}
.font48{
    font-size: 48px;
}

.matchParent{
    height: 100%;
    width: 100%;
}

.autoOverflow{
    
    overflow: hidden;
    
    top: 0;
    bottom: 0;
    /* height: 100vh; */
    /* min-height: 100%; */
}


.flexColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gap20{
    gap:20px
}

.gap50{
    gap:50px
}

.marginTop20{
    margin-top: 20px;
}
.marginTop50{
    margin-top: 50px;
}



.paymentForm{

    min-height: 300px;
    width: 100%;
    /* background-color: tomato; */
}

.checkbox{
    height: 20px;
    width: 20px;
    /* background-color: #7F79FC; */
}


.contained{
    background-color: #7F79FC;
    /* border-radius: ; */
}

.testRow{
    display: flex;
    flex-direction: row;
}