
.homeScreen{
    
    width: 100%;
    display: flex;
    flex-direction: column;
}


.topSectionHS{
    width: 100%;
    height: 248px;
    background-color: #F2F3FD;
}

.topSectionHSContent{
    
    width: 726px;
    margin-top: 64px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;

    
}


.topSectionHSIcon{
    width: 40px;
}

.topSectionHSTitle
{
    font-weight: 600;
    font-size: 40px;
    color: #766CE3;
    text-align: left;
}

.topSectionHSTitleBlack
{
    color: #151329;
}



.searchSectionHS{
    height: 110px;
    margin-top: -7px;
}

.searchSectionHSText{
    margin-left: 148px;
}

.mainSectionHS{
    width: 100%;
    background-color: white;
    margin-top: 80px;
    
}


.mainSectionHSContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 64px; */
    
}



.homeScreenSubSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.popularGymsHSTitle{
    display: flex;
    align-items: center;
    gap: 18px;
}

.popularGymsLocationList
{
    display: flex;
    gap: 12px;
    align-items: center;
}

.popularGymsLocation
{
    font-weight: 400;
    font-size: 16px;
    color: #151329;
    cursor: pointer;
}
.popularGymsLocation:hover{
    text-decoration: underline;
}

.popularGymsLocationListLine
{
    width: 2px;
    height: 14px;
    background-color: #72717C;
}

.popularGymsLocationRow{
    display: flex;
    gap:8px;
    align-items: center;
}

.popularGymsIcon{
    height: 16px;
}

.gymSliderHome{
    width: 100%;
    position: relative;
}



.homeSliderArrow{

    position: absolute;
    top: 116px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.leftHSA{
    left:-20px
}

.rightHSA{
    right:-20px
}

.gymTileRowHS{
    position: relative;
    /* height: 282px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 32px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 2px;


    
}


.gymTileContentHome{
    height: 123px;
    border-radius: 8px;

    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 22px;
    padding-right: 22px;

    margin-bottom: 104px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: -10px;
    background-color: white;

    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.gymTileContentHome.hover{
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
}

.gymTileContentHome.clicked{
    background-color: #F2F3FD;
}


.gymTilePriceHome{
    font-weight: 700;
    font-size: 20px;
}



.gymTileOptionsPopUpHome{

    position: absolute;
    top: 200px;
    left: 124px;
    z-index: 100;

    width: 220px;
    border-radius: 8px;
    background-color: white;
    
    padding: 20px;

    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    /* margin-top: -30px;
    margin-left: 30px; */
}


.noHomeTiles
{
    height: 409px;
    width: 100%;
    padding-top: 130px;
    box-sizing: border-box;
    
}


.pac-matched{
    color: #766CE3;
}

.pac-item{
    font-family: 'BeVietnamPro';
    font-size: 12px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.pac-item-query	{
    font-family: 'BeVietnamPro';
    font-weight: 500;
    font-size: 15px;
}

.pac-item:hover .pac-icon{
    background: url(../../public/assets/mapMarkerSelected.png);
    background-size: contain;
}


.pac-item-selected .pac-icon{
    background: url(../../public/assets/mapMarkerSelected.png);
    background-size: contain;
}


/* 
.pac-icon{
    background-image: url(../../public/assets/mapMarkerSelected.png);
    background-size: contain;
} */




@media (max-width:900px) {
    .topSectionHSContent{width: 100%; justify-content: center;}
    .topSectionHSIcon{width: 40px;}
    .topSectionHSTitle{font-size: 24px; max-width: 283px;}
    .discoverSearchRow{display: flex; flex-direction: column;}

    .mainSectionHSContent{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        align-items: center;
    }

    .popularGymsHSTitle{gap: 5px;}
    .homeSliderArrow{display: none;}


    .popularGymsLocationList{
        flex-flow: wrap;
        
    }

    .searchSectionHSText{
        margin-left: 20px;
        margin-top: 30px;
    }

    .gymTileRowHS{
        overflow-x: auto;
    }
}